<template>
  <v-container fluid class="px-0">
    <LinkPostList />
  </v-container>
</template>

<script>
import LinkPostList from "@/components/layout/link-posts/LinkPostList";
export default {
  name: "LinkPosts",
  components: { LinkPostList }
};
</script>

<style scoped></style>
